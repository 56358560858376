<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";
import { decodeHTML } from "entities";
import type { CSSProperties, VNode, VNodeArrayChildren } from "vue";
import type { NodeObject } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import { getOptionsFromNode } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@shopware/cms-base-layer/helpers/html-to-vue/renderToHtml";
type RawChildren = string | number | boolean | VNode | VNodeArrayChildren;

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const {
  headlineText,
  toplineText,
  headlineType,
  toplineType,
  textLeft,
  rightLeft,
} = props.content?.data;
const { getConfigValue } = useCmsElementConfig(props.content);
const mappedContent = computed<string>(() => {
  return textLeft;
});

const context = getCurrentInstance();

const CmsTextRender = defineComponent({
  setup() {
    const { resolveUrl } = useUrlResolver();

    const config = {
      textTransformer: (text: string) => decodeHTML(text),
      extraComponentsMap: {
        link: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              !node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            const _class = node.attrs?.class;
            return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
                href: node.attrs?.href
              },
              [...children]
            );
          },
        },
        button: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            let _class = "";
            if (node?.class) {
              const btnClass = "";

              _class = node.class
                .replace("btn-secondary", `${btnClass} btn-outline`)
                .replace("btn-primary", `${btnClass} btn`);
            }

            return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        font: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "font";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
            let newStyle = null;
            const styleColor = node?.attrs?.color;
            if (styleColor) {
              const currentStyle = node.attrs?.style ?? "";
              newStyle = `color:${styleColor};${currentStyle}`;
              delete node.attrs?.color;
            }

            return createElement(
              "span",
              {
                style: newStyle,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        img: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "img";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            return createElement(
              "img",
              getOptionsFromNode(node, resolveUrl)?.attrs
            );
          },
        },
      },
    };
    const rawHtml =
      mappedContent.value?.length > 0
        ? mappedContent.value
        : "<div class='cms-element-text missing-content-element'></div>";

    return () =>
      h("div", {}, renderHtml(rawHtml, config, h, context, resolveUrl));
  },
});
</script>
<template>
  <section>
    <div class="container px-0 xl:px-5">
      <SharedTopline
        :headline="toplineText"
        :headlineType="toplineType"
      ></SharedTopline>
      <SharedHeadline
        :headline="headlineText"
        :headlineType="headlineType"
        class="font-bold text-2xl mb-7.5"
      ></SharedHeadline>
      <div
        class="md:columns-2 gap-12 [&>p]:break-inside-avoid bio-circle-content"
      >
        <div class="[&>p]:text-[14px] [&>p]:leading-[21px] bio-circle-plain-text-content">
          <CmsTextRender />
        </div>
        <div
          class="[&>p]:text-[14px] [&>p]:leading-[21px]"
          v-html="rightLeft"
        ></div>
      </div>
    </div>
  </section>
</template>
